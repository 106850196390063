import { addClass } from "ak-react-components";
import { Title } from "components/Title";
import { FC } from "react";
import CreateInvoiceInformation from "./CreateInvoiceInformation";
import HistoryList from "./HistoryList";
import styles from "./InvoiceTab.module.scss";
import { useWarrantiesGetInvoiceInformationFiles } from "api/hooks/warranties/warranties";
import InfoBox from "./InfoBox";

const Wrapper = addClass("div", styles.wrapper);
const Section = addClass("div", styles.section);

const WarrantiesInvoiceTab: FC = () => {
  const invoiceInformationFileNamesQuery =
    useWarrantiesGetInvoiceInformationFiles();

  return (
    <Wrapper>
      <Section>
        <Title>Skapa fakturaunderlag</Title>
        <CreateInvoiceInformation
          onInvoiceInformationCreated={() =>
            invoiceInformationFileNamesQuery.refetch()
          }
        />
      </Section>

      <InfoBox appTextKey="INVOICE_DOCUMENTS_INFOBOX" />

      <Section>
        <Title>Skapade fakturaunderlag</Title>
        <HistoryList
          invoiceInformationFileNamesQuery={invoiceInformationFileNamesQuery}
        />
      </Section>
    </Wrapper>
  );
};

export default WarrantiesInvoiceTab;
