import { FormSectionInput } from "components/FormSection/FormSection";
import { FC } from "react";
import { useController, UseFormReturn, useWatch } from "react-hook-form";
import { EditWarrantyForm } from "./utils/formConverters";
import styles from "./WarrantyYearsInput.module.scss";
import { addClass } from "ak-react-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

const Wrapper = addClass("div", styles.wrapper);
const Warning = addClass("span", styles.warning);

interface Props {
  form: UseFormReturn<EditWarrantyForm>;
  readonly: boolean;
}

const WarrantyYearsInput: FC<Props> = ({ form, readonly }) => {
  const control = useController({
    control: form.control,
    name: "totalWarrantyYears",
  });

  const validFrom = useWatch({
    control: form.control,
    name: "validFrom",
  });
  const validTo = useWatch({
    control: form.control,
    name: "validTo",
  });

  const inputtedYearSpan =
    validTo && validFrom
      ? new Date(validTo).getFullYear() - new Date(validFrom).getFullYear()
      : 0;

  return (
    <Wrapper>
      {readonly && control.field.value === null ? (
        <span>N/A</span>
      ) : (
        <>
          <FormSectionInput
            readOnly={readonly}
            $readonlyStyle={readonly}
            type="number"
            {...control.field}
            value={control.field.value ?? ""}
            onChange={(e) => {
              control.field.onChange(
                e.target.value ? Number(e.target.value) : null,
              );
            }}
          />
        </>
      )}

      {inputtedYearSpan !== control.field.value && (
        <Warning>
          <FontAwesomeIcon icon={faWarning} />{" "}
          {control.field.value === null ? (
            <>Beräknade antal år: {inputtedYearSpan}</>
          ) : (
            <>
              OBS: Skillnaden mellan giltighetsdatumen är {inputtedYearSpan} år.
            </>
          )}
        </Warning>
      )}
    </Wrapper>
  );
};

export default WarrantyYearsInput;
