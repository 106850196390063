import Header from "./components/Header";
import styled, { createGlobalStyle } from "styled-components";
import { Theme } from "./utils/theme";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import ErrorModal from "./components/ErrorModal";
import { userLogOut } from "./api/hooks/user/user";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useAxiosDateConverter from "./utils/useAxiosDateConverter";
import routes, { routeDefinitions } from "./constants/routes";
import { RenderModalStack } from "utils/useModalStack";

const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();
  const [interceptorsAdded, setInterceptorsAdded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const location = useLocation();
  const [apiError, setApiError] = useState(undefined);

  useAxiosDateConverter();
  useEffect(() => {
    const interceptorId = axios.interceptors.response.use(
      (response) => {
        setIsAuthenticated(true);
        return response;
      },
      (error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          setIsAuthenticated(false);
          console.log("401 or 403");

          if (location.pathname !== routes.login) {
            navigate(routes.login);
            console.log("navigating to login");
          }

          return Promise.reject(error);
        } else if (
          error.response.status >= 500 &&
          error.response.status < 600
        ) {
          setApiError(error.message);

          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      },
    );

    setInterceptorsAdded(true);
    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [navigate, location.pathname]);

  const handleLogOut = () => {
    userLogOut().then(() => {
      setIsAuthenticated(false);
    });
  };

  const router = useRoutes(routeDefinitions);

  return (
    <QueryClientProvider client={queryClient}>
      <RenderModalStack />

      {!!apiError && (
        <ErrorModal error={apiError} onClose={() => setApiError(undefined)} />
      )}
      <Header isLoggedIn={isAuthenticated} onLogOut={handleLogOut} />
      {interceptorsAdded && router}
    </QueryClientProvider>
  );
}

export default App;
