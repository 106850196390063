import { FC } from "react";
import useSearchControls, { SearchType } from "./useSearchControls";
import styles from "./SearchControls.module.scss";
import {
  addClass,
  Button,
  Checkbox,
  Input,
  InputLabel,
  Select,
} from "ak-react-components";
import { PageControls, PageControlsRow } from "components/PageControls";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const SearchButton = addClass(Button, styles.searchButton);
const RegnrSearchWrapper = addClass("div", styles.regnrSearchWrapper);
const MyCheckbox = addClass(Checkbox, styles.myCheckbox);

interface Props {
  searchControls: ReturnType<typeof useSearchControls>;
  onSearchClick: () => void;
  disableSearchButton: boolean;
  isSearching: boolean;
}

const SearchControls: FC<Props> = ({
  searchControls,
  onSearchClick,
  disableSearchButton,
  isSearching,
}) => {
  const {
    searchInput,
    setSearchInput,
    searchType,
    setSearchType,
    useDateRange,
    setUseDateRange,
    fromDateStr,
    setFromDateStr,
    toDateStr,
    setToDateStr,
    hideCancelled,
    setHideCancelled,
  } = searchControls;

  return (
    <PageControls>
      <PageControlsRow>
        <InputLabel label="Sök">
          <RegnrSearchWrapper>
            <Input
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
              placeholder={
                searchType === SearchType.Chassinr ? "Chassinummer" : "ABC 123"
              }
            />
            <Select
              value={searchType}
              onChange={(e) => setSearchType(e.target.value as SearchType)}
            >
              <option value={SearchType.Regnr}>Regnr</option>
              <option value={SearchType.Chassinr}>Chassinr</option>
            </Select>
          </RegnrSearchWrapper>
        </InputLabel>

        <div />

        <MyCheckbox
          checked={useDateRange}
          onChange={(e) => setUseDateRange(e.currentTarget.checked)}
        >
          Inom datumperiod
        </MyCheckbox>

        {useDateRange && (
          <>
            <InputLabel label="Från">
              <Input
                type="datetime-local"
                value={fromDateStr}
                max={toDateStr}
                onChange={(e) => setFromDateStr(e.target.value)}
              />
            </InputLabel>
            <InputLabel label="Till">
              <Input
                type="datetime-local"
                value={toDateStr}
                min={fromDateStr}
                onChange={(e) => setToDateStr(e.target.value)}
              />
            </InputLabel>
          </>
        )}

        <SearchButton onClick={onSearchClick} disabled={disableSearchButton}>
          {isSearching ? <LoadingSpinner /> : null} Sök
        </SearchButton>
      </PageControlsRow>

      <PageControlsRow>
        <MyCheckbox
          checked={hideCancelled}
          onChange={(e) => setHideCancelled(e.currentTarget.checked)}
        >
          Dölj makulerade
        </MyCheckbox>
      </PageControlsRow>
    </PageControls>
  );
};

export default SearchControls;
