import { addClass, Input, InputLabel } from "ak-react-components";
import { useWarrantiesCreateInvoiceInformation } from "api/hooks/warranties/warranties";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Modal from "components/Modal/Modal";
import { FC, useState } from "react";
import { getDateString } from "utils/date";
import styles from "./CreateInvoiceInformationModal.module.scss";

const MyModal = addClass(Modal, styles.myModal);

interface Props {
  startDate: Date;
  endDate: Date;
  onInvoiceInformationCreated: () => void;
  onCloseClick: () => void;
}

const CreateInvoiceInformationModal: FC<Props> = ({
  onInvoiceInformationCreated,
  startDate,
  endDate,
  onCloseClick,
}) => {
  const [comment, setComment] = useState("");

  const createInvoiceInformationMutation =
    useWarrantiesCreateInvoiceInformation({
      mutation: {
        onSuccess: () => {
          onInvoiceInformationCreated();
        },
      },
    });

  return (
    <MyModal
      title="Skapa fakturaunderlag"
      onClose={
        createInvoiceInformationMutation.isPending ? undefined : onCloseClick
      }
      buttons={[
        {
          label: "Skapa",
          icon: createInvoiceInformationMutation.isPending ? (
            <LoadingSpinner />
          ) : undefined,
          disabled: createInvoiceInformationMutation.isPending,
          onClick: () => {
            createInvoiceInformationMutation.mutate({
              data: {
                period: { fromDate: startDate, toDate: endDate },
                comment,
              },
            });
          },
        },
        {
          label: "Avbryt",
          onClick: onCloseClick,
          disabled: createInvoiceInformationMutation.isPending,
        },
      ]}
    >
      <div>
        Datumperiod: {getDateString(startDate)} - {getDateString(endDate)}
      </div>
      <InputLabel label="Kommentar">
        <Input value={comment} onChange={(e) => setComment(e.target.value)} />
      </InputLabel>
    </MyModal>
  );
};

export default CreateInvoiceInformationModal;
