/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * AK Insurance Lookup API
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  ExternalCreateWarrantyInput,
  ExternalInvoiceInformation,
  ExternalSearchWarrantyInput,
  ExternalSearchWarrantyOutput,
  ExternalUpdateWarrantyInput,
  ExternalUpdateWarrantyOutput,
  InitialInvoiceValuesOutput,
  WarrantiesSearchInvoiceInformationFileParams,
  WarrantiesUpdateWarrantyParams,
} from "../../models";

export const warrantiesSearchWarranties = (
  externalSearchWarrantyInput: ExternalSearchWarrantyInput,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExternalSearchWarrantyOutput>> => {
  return axios.post(
    `/api/Warranties/search`,
    externalSearchWarrantyInput,
    options,
  );
};

export const getWarrantiesSearchWarrantiesMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof warrantiesSearchWarranties>>,
    TError,
    { data: ExternalSearchWarrantyInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof warrantiesSearchWarranties>>,
  TError,
  { data: ExternalSearchWarrantyInput },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof warrantiesSearchWarranties>>,
    { data: ExternalSearchWarrantyInput }
  > = (props) => {
    const { data } = props ?? {};

    return warrantiesSearchWarranties(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WarrantiesSearchWarrantiesMutationResult = NonNullable<
  Awaited<ReturnType<typeof warrantiesSearchWarranties>>
>;
export type WarrantiesSearchWarrantiesMutationBody =
  ExternalSearchWarrantyInput;
export type WarrantiesSearchWarrantiesMutationError = AxiosError<unknown>;

export const useWarrantiesSearchWarranties = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof warrantiesSearchWarranties>>,
    TError,
    { data: ExternalSearchWarrantyInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getWarrantiesSearchWarrantiesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const warrantiesGetInvoiceInformationFiles = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExternalInvoiceInformation[]>> => {
  return axios.get(`/api/Warranties/invoiceinformation`, options);
};

export const getWarrantiesGetInvoiceInformationFilesQueryKey = () => {
  return [`/api/Warranties/invoiceinformation`] as const;
};

export const getWarrantiesGetInvoiceInformationFilesQueryOptions = <
  TData = Awaited<ReturnType<typeof warrantiesGetInvoiceInformationFiles>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof warrantiesGetInvoiceInformationFiles>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getWarrantiesGetInvoiceInformationFilesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof warrantiesGetInvoiceInformationFiles>>
  > = ({ signal }) =>
    warrantiesGetInvoiceInformationFiles({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof warrantiesGetInvoiceInformationFiles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WarrantiesGetInvoiceInformationFilesQueryResult = NonNullable<
  Awaited<ReturnType<typeof warrantiesGetInvoiceInformationFiles>>
>;
export type WarrantiesGetInvoiceInformationFilesQueryError =
  AxiosError<unknown>;

export const useWarrantiesGetInvoiceInformationFiles = <
  TData = Awaited<ReturnType<typeof warrantiesGetInvoiceInformationFiles>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof warrantiesGetInvoiceInformationFiles>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWarrantiesGetInvoiceInformationFilesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const warrantiesCreateInvoiceInformation = (
  externalCreateWarrantyInput: ExternalCreateWarrantyInput,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<string>> => {
  return axios.post(
    `/api/Warranties/invoiceinformation`,
    externalCreateWarrantyInput,
    options,
  );
};

export const getWarrantiesCreateInvoiceInformationMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof warrantiesCreateInvoiceInformation>>,
    TError,
    { data: ExternalCreateWarrantyInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof warrantiesCreateInvoiceInformation>>,
  TError,
  { data: ExternalCreateWarrantyInput },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof warrantiesCreateInvoiceInformation>>,
    { data: ExternalCreateWarrantyInput }
  > = (props) => {
    const { data } = props ?? {};

    return warrantiesCreateInvoiceInformation(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WarrantiesCreateInvoiceInformationMutationResult = NonNullable<
  Awaited<ReturnType<typeof warrantiesCreateInvoiceInformation>>
>;
export type WarrantiesCreateInvoiceInformationMutationBody =
  ExternalCreateWarrantyInput;
export type WarrantiesCreateInvoiceInformationMutationError =
  AxiosError<unknown>;

export const useWarrantiesCreateInvoiceInformation = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof warrantiesCreateInvoiceInformation>>,
    TError,
    { data: ExternalCreateWarrantyInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions =
    getWarrantiesCreateInvoiceInformationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const warrantiesGetInitialInvoiceValues = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<InitialInvoiceValuesOutput>> => {
  return axios.get(`/api/Warranties/initialvalues`, options);
};

export const getWarrantiesGetInitialInvoiceValuesQueryKey = () => {
  return [`/api/Warranties/initialvalues`] as const;
};

export const getWarrantiesGetInitialInvoiceValuesQueryOptions = <
  TData = Awaited<ReturnType<typeof warrantiesGetInitialInvoiceValues>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof warrantiesGetInitialInvoiceValues>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getWarrantiesGetInitialInvoiceValuesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof warrantiesGetInitialInvoiceValues>>
  > = ({ signal }) =>
    warrantiesGetInitialInvoiceValues({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof warrantiesGetInitialInvoiceValues>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WarrantiesGetInitialInvoiceValuesQueryResult = NonNullable<
  Awaited<ReturnType<typeof warrantiesGetInitialInvoiceValues>>
>;
export type WarrantiesGetInitialInvoiceValuesQueryError = AxiosError<unknown>;

export const useWarrantiesGetInitialInvoiceValues = <
  TData = Awaited<ReturnType<typeof warrantiesGetInitialInvoiceValues>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof warrantiesGetInitialInvoiceValues>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getWarrantiesGetInitialInvoiceValuesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const warrantiesSearchInvoiceInformationFile = (
  params?: WarrantiesSearchInvoiceInformationFileParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Blob>> => {
  return axios.get(`/api/Warranties/invoiceinformation/search`, {
    responseType: "blob",
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWarrantiesSearchInvoiceInformationFileQueryKey = (
  params?: WarrantiesSearchInvoiceInformationFileParams,
) => {
  return [
    `/api/Warranties/invoiceinformation/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getWarrantiesSearchInvoiceInformationFileQueryOptions = <
  TData = Awaited<ReturnType<typeof warrantiesSearchInvoiceInformationFile>>,
  TError = AxiosError<unknown>,
>(
  params?: WarrantiesSearchInvoiceInformationFileParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof warrantiesSearchInvoiceInformationFile>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getWarrantiesSearchInvoiceInformationFileQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof warrantiesSearchInvoiceInformationFile>>
  > = ({ signal }) =>
    warrantiesSearchInvoiceInformationFile(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof warrantiesSearchInvoiceInformationFile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WarrantiesSearchInvoiceInformationFileQueryResult = NonNullable<
  Awaited<ReturnType<typeof warrantiesSearchInvoiceInformationFile>>
>;
export type WarrantiesSearchInvoiceInformationFileQueryError =
  AxiosError<unknown>;

export const useWarrantiesSearchInvoiceInformationFile = <
  TData = Awaited<ReturnType<typeof warrantiesSearchInvoiceInformationFile>>,
  TError = AxiosError<unknown>,
>(
  params?: WarrantiesSearchInvoiceInformationFileParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof warrantiesSearchInvoiceInformationFile>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWarrantiesSearchInvoiceInformationFileQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const warrantiesUpdateWarranty = (
  externalUpdateWarrantyInput: ExternalUpdateWarrantyInput,
  params?: WarrantiesUpdateWarrantyParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExternalUpdateWarrantyOutput>> => {
  return axios.put(`/api/Warranties`, externalUpdateWarrantyInput, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getWarrantiesUpdateWarrantyMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof warrantiesUpdateWarranty>>,
    TError,
    {
      data: ExternalUpdateWarrantyInput;
      params?: WarrantiesUpdateWarrantyParams;
    },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof warrantiesUpdateWarranty>>,
  TError,
  {
    data: ExternalUpdateWarrantyInput;
    params?: WarrantiesUpdateWarrantyParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof warrantiesUpdateWarranty>>,
    {
      data: ExternalUpdateWarrantyInput;
      params?: WarrantiesUpdateWarrantyParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return warrantiesUpdateWarranty(data, params, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WarrantiesUpdateWarrantyMutationResult = NonNullable<
  Awaited<ReturnType<typeof warrantiesUpdateWarranty>>
>;
export type WarrantiesUpdateWarrantyMutationBody = ExternalUpdateWarrantyInput;
export type WarrantiesUpdateWarrantyMutationError = AxiosError<unknown>;

export const useWarrantiesUpdateWarranty = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof warrantiesUpdateWarranty>>,
    TError,
    {
      data: ExternalUpdateWarrantyInput;
      params?: WarrantiesUpdateWarrantyParams;
    },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getWarrantiesUpdateWarrantyMutationOptions(options);

  return useMutation(mutationOptions);
};
