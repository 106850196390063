import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { FC, useState } from "react";
import BlotFormatter, {
  ResizeAction,
  DeleteAction,
  ImageSpec,
} from "quill-blot-formatter";
import { addClass, Button } from "ak-react-components";
import styles from "./RichTextEditor.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

Quill.register("modules/blotFormatter", BlotFormatter);

const Wrapper = addClass("div", styles.wrapper);
const ButtonsWrapper = addClass("div", styles.buttonsWrapper);
const MyStyledFontAwesome = addClass(
  FontAwesomeIcon,
  styles.myStyledFontAwesome,
);
const QuillWrapper = addClass("div", styles.quillWrapper);
const LengthError = addClass("div", styles.lengthError);
const ReadOnlyWrapper = addClass("div", styles.readOnlyWrapper);
const EditFab = addClass(Button, styles.editFab);

class CustomImageSpec extends ImageSpec {
  getActions() {
    return [ResizeAction, DeleteAction];
  }
}

Quill.register("modules/blotFormatter", BlotFormatter);

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, false] }],
      [{ font: [] }],
      [{ align: [] }, { indent: "-1" }, { indent: "+1" }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      ["clean"],
      ["link", "image"],
    ],
  },
  blotFormatter: {
    specs: [CustomImageSpec],
  },
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "direction",
  "code-block",
  "background",
  "color",
  "font",
  "code",
  "size",
  "script",
  "width",
  "height",
];

interface Props {
  htmlText: string;
  onSaveClick?(editText: string): void;
  onEditClick?(): void;
  onCancelClick?(): void;
  className?: string;
}

const RichTextEditor: FC<Props> = ({
  htmlText,
  onSaveClick,
  onCancelClick,
  onEditClick,
  className,
}) => {
  const [editBuffer, setEditBuffer] = useState(htmlText);

  const editMode = !!onSaveClick;

  const handleCancelClick = () => {
    setEditBuffer(htmlText);
    onCancelClick?.();
  };

  const handleSaveClick = () => {
    onSaveClick?.(editBuffer);
  };

  // JavaScriptSerializer.MaxJsonLength == 2097152 characters, set upload limit to 2000000 characters.
  const exceededUploadLimit = editBuffer?.length > 2000000;

  return (
    <Wrapper className={className}>
      {onSaveClick ? (
        <QuillWrapper>
          <ReactQuill
            theme="snow"
            value={editBuffer}
            onChange={setEditBuffer}
            modules={modules}
            formats={formats}
          />
          {exceededUploadLimit && <LengthError>För stort innehåll</LengthError>}
        </QuillWrapper>
      ) : (
        <ReadOnlyWrapper>
          <ReactQuill theme="bubble" value={htmlText} readOnly />
        </ReadOnlyWrapper>
      )}

      {!editMode && onEditClick && (
        <EditFab onClick={onEditClick}>
          <FontAwesomeIcon icon={faEdit} />
        </EditFab>
      )}

      {editMode && (
        <ButtonsWrapper>
          {onCancelClick && <Button onClick={handleCancelClick}>Avbryt</Button>}
          <Button
            onClick={handleSaveClick}
            disabled={
              exceededUploadLimit || !editBuffer || editBuffer === htmlText
            }
          >
            <MyStyledFontAwesome icon={faSave} /> Spara
          </Button>
        </ButtonsWrapper>
      )}
    </Wrapper>
  );
};

export default RichTextEditor;
