import { addClass } from "ak-react-components";
import { FC, PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import styles from "./PageLink.module.scss";

const Wrapper = addClass("li", styles.wrapper);

interface Props extends PropsWithChildren {
  to: string;
}

const PageLink: FC<Props> = ({ to, children }) => {
  return (
    <Wrapper>
      <NavLink
        to={to}
        className={({ isActive }) => (isActive ? styles.active : "")}
      >
        {children}
      </NavLink>
    </Wrapper>
  );
};

export default PageLink;
