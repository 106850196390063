import { faTrash, faTrashAlt, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addClass } from "ak-react-components";
import { useWarrantiesUpdateWarranty } from "api/hooks/warranties/warranties";
import FormSection, { InputType } from "components/FormSection/FormSection";
import Modal from "components/Modal/Modal";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import SlideIn from "components/SlideIn/SlideIn";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import useModalStack from "utils/useModalStack";
import styles from "./EditWarrantySlideIn.module.scss";
import {
  convertEditWarrantyFormToWarranty,
  EditWarrantyForm,
} from "./utils/formConverters";
import WarrantyYearsInput from "./WarrantyYearsInput";

const Inputs = addClass("div", styles.inputs);
const CancelledLabel = addClass("span", styles.cancelledLabel);

interface Props {
  form: UseFormReturn<EditWarrantyForm>;
  onCloseClick: () => void;
  onWarrantySaved: () => void;
  editMode: boolean;
  onEditClick: () => void;
  onCancelEditClick: () => void;
}

const EditWarrantySlideIn: FC<Props> = ({
  form,
  onCloseClick,
  onWarrantySaved,
  editMode,
  onEditClick,
  onCancelEditClick,
}) => {
  const modalStack = useModalStack();
  const warrantyMutation = useWarrantiesUpdateWarranty();

  const warrantyIsCancelled = form.watch("cancelled");

  const handleSaveWarranty = (formValues: EditWarrantyForm) => {
    warrantyMutation.mutate(
      {
        data: convertEditWarrantyFormToWarranty(formValues),
        params: {
          id: formValues.id,
        },
      },
      {
        onSuccess: (res) => {
          if (res.data.success) {
            form.reset(formValues);
            onWarrantySaved();
          }
        },
      },
    );
  };

  const handleSaveClick = form.handleSubmit((formValues) => {
    handleSaveWarranty(formValues);
  });

  const handleCancelWarrantyClick = () => {
    const modalId = modalStack.push(
      <Modal
        title="Makulera garanti"
        onClose={() => modalStack.pop(modalId)}
        buttons={[
          {
            label: "Makulera",
            onClick: () => {
              handleSaveWarranty({
                ...form.getValues(),
                cancelled: true,
              });
              modalStack.pop(modalId);
            },
          },
          {
            label: "Avbryt",
            onClick: () => modalStack.pop(modalId),
          },
        ]}
      >
        Är du säker på att du vill makulera garantin?
      </Modal>,
    );
  };

  const handleOpenWarrantyClick = () => {
    const modalId = modalStack.push(
      <Modal
        title="Öppna garanti"
        onClose={() => modalStack.pop(modalId)}
        buttons={[
          {
            label: "Öppna",
            onClick: () => {
              handleSaveWarranty({
                ...form.getValues(),
                cancelled: false,
              });
              modalStack.pop(modalId);
            },
          },
          {
            label: "Avbryt",
            onClick: () => modalStack.pop(modalId),
          },
        ]}
      >
        Är du säker på att du vill öppna garantin?
      </Modal>,
    );
  };

  return (
    <SlideIn
      title={
        <>
          {editMode ? "Redigerar garanti" : "Garanti"}{" "}
          {warrantyIsCancelled && (
            <CancelledLabel>
              <FontAwesomeIcon icon={faTrashAlt} /> Makulerad
            </CancelledLabel>
          )}
        </>
      }
      onClose={onCloseClick}
      actions={
        editMode
          ? [
              {
                icon: <FontAwesomeIcon icon={faUndo} />,
                label: "Återställ",
                onClick: () => form.reset(),
                hide: !form.formState.isDirty || warrantyMutation.isPending,
              },
              {
                label: "Spara",
                icon: warrantyMutation.isPending ? <LoadingSpinner /> : null,
                disabled: warrantyMutation.isPending,
                onClick: handleSaveClick,
              },
              {
                label: "Avbryt",
                onClick: onCancelEditClick,
                hide: warrantyMutation.isPending,
              },
            ]
          : warrantyIsCancelled
            ? [
                {
                  label: "Öppna garanti",
                  icon: warrantyMutation.isPending && <LoadingSpinner />,
                  onClick: handleOpenWarrantyClick,
                  disabled: warrantyMutation.isPending,
                },
              ]
            : [
                {
                  label: "Makulera",
                  icon: warrantyMutation.isPending ? (
                    <LoadingSpinner />
                  ) : (
                    <FontAwesomeIcon icon={faTrash} />
                  ),
                  onClick: handleCancelWarrantyClick,
                  disabled: warrantyMutation.isPending,
                },
                {
                  label: "Redigera",
                  onClick: onEditClick,
                  disabled: warrantyMutation.isPending,
                },
              ]
      }
    >
      <Inputs>
        <FormSection
          form={form}
          readonly={!editMode}
          inputs={[
            {
              type: InputType.Text,
              name: "registrationNumber",
              label: "Regnummer",
            },
            {
              type: InputType.Text,
              name: "chassisNumber",
              label: "Chassinummer",
            },
            {
              type: InputType.Text,
              name: "vehicleModel",
              label: "Modell",
            },
            {
              type: InputType.Text,
              name: "vehicleBrand",
              label: "Märke",
            },
            {
              type: InputType.Text,
              name: "vehicleCountry",
              label: "Tillverkningsland",
            },
            {
              type: InputType.Text,
              name: "vehicleYearModel",
              label: "Årsmodell",
            },
            {
              type: InputType.Date,
              name: "validFrom",
              label: "Giltig från",
            },
            {
              type: InputType.Date,
              name: "validTo",
              label: "Giltig till",
            },
            {
              type: InputType.Custom,
              label: "Antal garantiår",
              render: WarrantyYearsInput,
            },
            {
              type: InputType.Text,
              name: "externalId",
              label: "Externt id",
            },
          ]}
        />
      </Inputs>
    </SlideIn>
  );
};

export default EditWarrantySlideIn;
