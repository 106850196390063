import {
  faArrowAltCircleUp,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useRef, useState } from "react";
import styled from "styled-components";
import { Theme } from "../utils/theme";

const Wrapper = styled.div<{ $isDragOver?: boolean; $success?: boolean }>`
  padding: 15px;
  flex: 1;
  border: 5px dashed
    ${({ $isDragOver }) =>
      $isDragOver ? Theme.colors.primary : Theme.colors.disabled};
  background-color: ${Theme.colors.bg.content};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: ${({ $isDragOver }) =>
    $isDragOver ? Theme.colors.primary : Theme.colors.disabled};
  cursor: pointer;

  span {
    color: ${Theme.colors.text.secondary};
  }

  svg {
    color: ${({ $success }) =>
      $success ? Theme.colors.status.success : Theme.colors.primary};
  }

  input {
    display: none;
  }

  &:hover {
    border-color: ${Theme.colors.primary};
    color: ${Theme.colors.primary};
  }
`;

interface Props {
  onFileUpload: (files: File[]) => void;
  success?: boolean;
}

const FileInput: FC<Props> = ({ onFileUpload, success }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList: File[] = [];
    if (e.target.files) {
      Object.entries(e.target.files).forEach(([key, value]) => {
        fileList.push(value);
      });
      onFileUpload(fileList);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const dropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDragOver(false);

    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();

    if (e.dataTransfer.items) {
      const fileList: File[] = [];
      Object.entries(e.dataTransfer.items).forEach(([key, value]) => {
        if (value.kind === "file" && value.type === "text/csv") {
          const file = value.getAsFile();
          if (file) {
            fileList.push(file);
          }
        }
      });
      onFileUpload(fileList);
    }
  };

  return (
    <Wrapper
      onClick={() => fileInputRef.current?.click()}
      $isDragOver={isDragOver}
      onDrop={dropHandler}
      onDragOver={onDragOver}
      onDragLeave={() => setIsDragOver(false)}
      $success={success}
    >
      <input ref={fileInputRef} onChange={onFileInputChange} type="file" />

      {success ? (
        <FontAwesomeIcon icon={faCheckCircle} size={"3x"} />
      ) : (
        <FontAwesomeIcon icon={faArrowAltCircleUp} size={"3x"} />
      )}

      {success ? (
        <span>Uppladdningen lyckades!</span>
      ) : (
        <span>Dra och släpp eller klicka för att ladda upp en fil</span>
      )}
    </Wrapper>
  );
};

export default FileInput;
