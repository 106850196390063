import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import FileInput from "../../../components/FileInput";
import { Title } from "../../../components/Title";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme } from "../../../utils/theme";
import { filesUploadFile } from "../../../api/hooks/files/files";
import { Button, ReactTable } from "ak-react-components";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const Wrapper = styled.div``;

const InnerWrapper = styled.div`
  padding: 20px;
  background-color: ${Theme.colors.bg.content};
  display: flex;
  flex-direction: column;
  min-height: 410px;
`;

const DeleteButton = styled.button`
  color: ${Theme.colors.primary};
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const ErrorMsg = styled.p`
  margin-top: 10px;
  margin-bottom: 0;
  color: ${Theme.colors.status.error};
  font-size: 12px;
`;

const SendButton = styled(Button)`
  margin-top: 15px;
  align-self: flex-end;
`;
interface Props {
  onUpload: () => void;
}

const FileUploader: FC<Props> = ({ onUpload }) => {
  const [file, setFile] = useState<File>();
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSendFiles = async () => {
    if (!file) return;
    setIsLoading(true);

    try {
      const res = await filesUploadFile({ file });

      if (res.data) {
        setFile(undefined);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3500);
        onUpload();
      }
    } catch (error) {
      setFailed(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setFailed(false);
  }, [file]);

  return (
    <Wrapper>
      <Title>Uppladdning</Title>
      <InnerWrapper>
        <FileInput
          onFileUpload={(newFiles) => setFile(newFiles[0])}
          success={success}
        />
        {file && (
          <ReactTable
            asList
            columns={[
              {
                header: "Filnamn",
                accessorKey: "name",
              },
              {
                header: "Ta bort",
                size: 100,
                cell: () => (
                  <DeleteButton onClick={() => setFile(undefined)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </DeleteButton>
                ),
              },
            ]}
            data={[
              {
                name: file.name,
              },
            ]}
          />
        )}
        {failed && <ErrorMsg>Uppladdning misslyckades</ErrorMsg>}
        {file && (
          <SendButton
            disabled={!file || failed || isLoading}
            onClick={() => onSendFiles()}
          >
            {isLoading ? <LoadingSpinner /> : "Skicka"}
          </SendButton>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

export default FileUploader;
