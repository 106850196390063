import { RouteObject } from "react-router-dom";
import { DeepReadonly } from "../../utils/object";

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

type RemoveRouteParam<T extends string> = T extends `${infer Start}/:${infer _}`
  ? Start
  : T;

type RoutesConstantsObject<
  Routes,
  RouteAcc extends string = "",
> = UnionToIntersection<
  Routes extends DeepReadonly<Array<infer RO>>
    ? RO extends DeepReadonly<RouteObject>
      ? RO["path"] extends string
        ? RO["path"] extends ""
          ? never
          : {
              [K in RemoveRouteParam<
                RO["path"]
              >]: RO["children"] extends DeepReadonly<Array<RouteObject>>
                ? UnionToIntersection<
                    RoutesConstantsObject<
                      RO["children"],
                      `${RouteAcc}/${RO["path"]}`
                    >
                  >
                : `${RouteAcc}/${RO["path"]}`;
            } & { index: RouteAcc }
        : never
      : never
    : never
>;

function generatePathsFromRouteObjects<RO extends DeepReadonly<RouteObject[]>>(
  routes: RO,
  currentPath: string[],
) {
  const pathsObj: { [key: string]: any } = {};

  for (const route of routes) {
    if (route.path) {
      const objKey = route.path.split("/:")[0];

      if (route.children) {
        pathsObj[objKey] = generatePathsFromRouteObjects(route.children, [
          ...currentPath,
          route.path,
        ]);
      } else {
        pathsObj[objKey] = "/" + [...currentPath, route.path].join("/");
      }
    }
  }

  return {
    ...pathsObj,
    index: "/" + currentPath.join("/"),
  } as RoutesConstantsObject<RO>;
}

export default generatePathsFromRouteObjects;
