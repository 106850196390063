import { FC, useState } from "react";
import styled from "styled-components";
import { Theme } from "../../utils/theme";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { userLogin } from "../../api/hooks/user/user";
import routes from "../../constants/routes";
import { Button, Input, InputLabel } from "ak-react-components";

const Wrapper = styled.div`
  flex: 1;
  align-self: center;
  display: flex;
  transform: translateY(-10%);
  min-width: 30vw;
`;

export const LoginTitle = styled.h2`
  margin: 0;
  padding: 0;
`;

export const LoginForm = styled.form`
  flex: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: ${Theme.sizes.padding.screenInset};
  padding-bottom: calc(2 * ${Theme.sizes.padding.screenInset});
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

export const ErrorText = styled.div`
  color: ${Theme.colors.status.error};
`;

const LoginPage: FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState<string>();
  const navigate = useNavigate();

  const handleLoginClick = async (eve: React.FormEvent) => {
    eve.preventDefault();
    setErrorMsg(undefined);

    if (username && password) {
      try {
        await userLogin({
          username,
          password,
        });
        navigate(routes.upload);
      } catch (err) {
        if (err instanceof AxiosError) {
          setErrorMsg(err.response?.data);
        }
      }
    }
  };

  return (
    <Wrapper>
      <LoginForm onSubmit={handleLoginClick}>
        <LoginTitle>Logga in</LoginTitle>

        <InputLabel label="Användarnamn">
          <Input
            type="text"
            onChange={(e) => setUsername(e.currentTarget.value)}
            value={username}
          />
        </InputLabel>
        <InputLabel label="Lösenord">
          <Input
            type="password"
            onChange={(e) => setPassword(e.currentTarget.value)}
            value={password}
          />
        </InputLabel>
        {errorMsg && <ErrorText>{errorMsg}</ErrorText>}

        <ButtonsRow>
          <Button type="submit" disabled={!username || !password}>
            Logga In
          </Button>
        </ButtonsRow>
      </LoginForm>
    </Wrapper>
  );
};

export default LoginPage;
