import { FC, useEffect, useState } from "react";
import { InputLabel, Input, Button, addClass } from "ak-react-components";
import styles from "./CreateInvoiceInformation.module.scss";
import useModalStack from "utils/useModalStack";
import CreateInvoiceInformationModal from "./CreateInvoiceInformationModal";
import { warrantiesGetInitialInvoiceValues } from "api/hooks/warranties/warranties";
import { toInputDateString } from "utils/date";

const InputRow = addClass("div", styles.inputRow);
const MyInputLabel = addClass(InputLabel, styles.flex);

interface Props {
  onInvoiceInformationCreated: () => void;
}

const CreateInvoiceInformation: FC<Props> = ({
  onInvoiceInformationCreated,
}) => {
  const modalStack = useModalStack();
  const [startDateInput, setStartDateInput] = useState("");
  const [endDateInput, setEndDateInput] = useState("");

  const startDate = new Date(startDateInput);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(endDateInput);
  endDate.setHours(23, 59, 59, 999);

  const allowCreate = !isNaN(startDate.getTime()) && !isNaN(endDate.getTime());

  const fetchInitialValues = async () => {
    try {
      const result = await warrantiesGetInitialInvoiceValues();

      setEndDateInput(toInputDateString(result.data.toDate));
      setStartDateInput(toInputDateString(result.data.fromDate));
    } catch (error) {
      console.error("Failed to fetch initial values", error);
    }
  };

  useEffect(() => {
    fetchInitialValues();
  }, []);

  const handleCreateClick = () => {
    const modalId = modalStack.push(
      <CreateInvoiceInformationModal
        startDate={startDate}
        endDate={endDate}
        onCloseClick={() => {
          modalStack.pop(modalId);
        }}
        onInvoiceInformationCreated={() => {
          onInvoiceInformationCreated();
          modalStack.pop(modalId);
          fetchInitialValues();
        }}
      />,
    );
  };

  return (
    <InputRow>
      <MyInputLabel label="Startdatum">
        <Input
          type="date"
          max={endDateInput}
          value={startDateInput}
          onChange={(eve) => setStartDateInput(eve.target.value)}
        />
      </MyInputLabel>

      <MyInputLabel label="Slutdatum">
        <Input
          type="date"
          min={startDateInput}
          value={endDateInput}
          onChange={(eve) => setEndDateInput(eve.target.value)}
        />
      </MyInputLabel>

      <Button disabled={!allowCreate} onClick={handleCreateClick}>
        Skapa
      </Button>
    </InputRow>
  );
};

export default CreateInvoiceInformation;
