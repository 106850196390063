import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AkThemeProvider } from "ak-react-components";
import { Theme } from "ak-react-components/dist/types/components/AkThemeProvider";
import { RecoilRoot } from "recoil";

type DeepOptional<T> = {
  [P in keyof T]?: DeepOptional<T[P]>;
};

const akComponentsTheme: DeepOptional<Theme> = {
  colors: {
    border: {
      main: "var(--theme-colors-border-main)",
      input: "var(--theme-colors-border-input)",
    },
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <AkThemeProvider theme={akComponentsTheme}>
          <App />
        </AkThemeProvider>
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
