import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addClass } from "ak-react-components";
import { FC, PropsWithChildren } from "react";
import styles from "./LoadingSpinnerOverlay.module.scss";

const Wrapper = addClass("div", styles.wrapper);
const Spinner = addClass(FontAwesomeIcon, styles.spinner);

const LoadingSpinnerOverlay: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Wrapper>
      <Spinner icon={faSpinner} spin />
      {children}
    </Wrapper>
  );
};

export default LoadingSpinnerOverlay;
