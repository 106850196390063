import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Page from "components/Page";
import routes from "constants/routes";
import { FC } from "react";
import { Outlet } from "react-router-dom";

const WarrantiesPage: FC = () => {
  return (
    <Page
      title="Garantier"
      tabs={[
        {
          label: (
            <>
              <FontAwesomeIcon icon={faSearch} /> Sök
            </>
          ),
          to: routes.warranties.search,
        },
        {
          label: "Fakturaunderlag",
          to: routes.warranties.invoice,
        },
      ]}
    >
      <Outlet />
    </Page>
  );
};

export default WarrantiesPage;
