import { FC } from "react";
import styled from "styled-components";
import { Title } from "../../../components/Title";
import { Theme } from "../../../utils/theme";
import { ExternalFile } from "../../../api/models";
import { filesGet } from "../../../api/hooks/files/files";
import { getDateString } from "../../../utils/date";
import { Button, ReactTable } from "ak-react-components";
import LinkButton from "components/LinkButton/LinkButton";
import { downloadBlob } from "utils/blob";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const Wrapper = styled.div`
  padding-bottom: 25px;
`;

const InnerWrapper = styled.div`
  padding: 20px;
  background-color: ${Theme.colors.bg.content};
  display: flex;
  flex-direction: column;
`;

const ErrorMsg = styled.p`
  text-align: center;
`;

interface Props {
  history: ExternalFile[];
  refetchHistory?: () => void;
  isLoading?: boolean;
}

const UploadHistory: FC<Props> = ({ history, refetchHistory, isLoading }) => {
  const download = async (blobId: string, filename: string) => {
    try {
      const response = await filesGet({ blobId });

      if (response.data) downloadBlob(response.data, filename);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Wrapper>
      <Title>Historik</Title>
      <InnerWrapper>
        {refetchHistory ? (
          <>
            <ErrorMsg>Något gick fel!</ErrorMsg>
            <Button onClick={refetchHistory}>Försök igen</Button>
          </>
        ) : isLoading ? (
          <LoadingSpinner />
        ) : (
          <ReactTable
            asList
            columns={[
              {
                header: "Uppladdningsdatum",
                accessorFn: (file) => getDateString(file.uploaded),
                maxSize: 200,
              },
              {
                header: "Innehåll",
                cell: (cell) => (
                  <LinkButton
                    onClick={() =>
                      cell.row.original.blobId &&
                      download(
                        cell.row.original.blobId,
                        `${getDateString(cell.row.original.uploaded)}_${cell.row.original.name}`,
                      )
                    }
                  >
                    {cell.row.original.name}
                  </LinkButton>
                ),
              },
            ]}
            data={history}
          />
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

export default UploadHistory;
