import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addClass, Button } from "ak-react-components";
import { FC, PropsWithChildren, useLayoutEffect, useRef } from "react";
import styles from "./Modal.module.scss";

const fadeInClassname = "fade-in-modal";
const fadeOutClassname = "fade-out-modal";
const fadingTime = 0.15;

export const modalTitleClass = "modal-title";
export const modalTitleCloseButtonClass = "modal-title-close-button";
export const modalBodyClass = "modal-body";
export const modalContentClass = "modal-content";
export const modalButtonsClass = "modal-buttons";

const Overlay = addClass("div", styles.overlay);
const ModalBody = addClass("div", [styles.modalBody, modalBodyClass]);
const ModalContent = addClass("div", [styles.modalContent, modalContentClass]);
const ModalTitleRow = addClass("div", styles.modalTitleRow);
const ModalTitle = addClass("h1", [styles.modalTitle, modalTitleClass]);
const ModalTitleCloseButton = addClass("div", [
  styles.modalTitleCloseButton,
  modalTitleCloseButtonClass,
]);
const ModalButtons = addClass("div", [styles.modalButtons, modalButtonsClass]);

export interface ModalButton {
  icon?: React.ReactNode;
  label: React.ReactNode;
  onClick?(this: void, eve: React.MouseEvent): void;
  disabled?: boolean;
}

interface Props extends PropsWithChildren {
  title?: string;
  buttons?: ModalButton[];
  onClose?(eve: React.MouseEvent | React.KeyboardEvent<HTMLDivElement>): void;
  className?: string;
}

const Modal: FC<Props> = ({ title, buttons, onClose, className, children }) => {
  return (
    <Overlay className={className}>
      <ModalBody
        onClick={(eve) => eve.stopPropagation()}
        className={modalBodyClass}
      >
        <ModalTitleRow>
          {title && (
            <ModalTitle className={modalTitleClass}>{title}</ModalTitle>
          )}
          {onClose && (
            <ModalTitleCloseButton
              className={modalTitleCloseButtonClass}
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faXmark} width={20} height={20} />
            </ModalTitleCloseButton>
          )}
        </ModalTitleRow>

        <ModalContent className={modalContentClass}>{children}</ModalContent>

        {buttons && buttons.length > 0 && (
          <ModalButtons className={modalButtonsClass}>
            {buttons.map((button, i) => (
              <Button
                disabled={button.disabled}
                key={i}
                onClick={button.onClick}
              >
                {button.icon} {button.label}
              </Button>
            ))}
          </ModalButtons>
        )}
      </ModalBody>
    </Overlay>
  );
};

export default Modal;
