import { Button } from "ak-react-components";
import { FC } from "react";
import styled from "styled-components";
import { Theme } from "../utils/theme";

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
`;

const Wrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  z-index: 100;
  background-color: ${Theme.colors.bg.main};
  padding: 20px;
`;

const ErrorText = styled.p`
  color: ${Theme.colors.status.error};
  font-style: italic;
`;

const MyButton = styled(Button)`
  float: right;
  margin-top: 10px;
`;

interface Props {
  error?: string;
  onClose: () => void;
}

const ErrorModal: FC<Props> = ({ error, onClose }) => {
  return (
    <BackDrop>
      <Wrapper>
        <h1>Något gick fel!</h1>
        <ErrorText>{error && error}</ErrorText>
        <p>Kontakta support, eller försök igen.</p>
        <MyButton onClick={() => onClose()}>Ok</MyButton>
      </Wrapper>
    </BackDrop>
  );
};

export default ErrorModal;
