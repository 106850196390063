import { addClass } from "ak-react-components";
import { FC, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Tabstrip.module.scss";

const Wrapper = addClass("div", styles.wrapper);

export type Tab = {
  label: ReactNode;
  to: string;
};

interface Props {
  tabs: Tab[];
}

const Tabstrip: FC<Props> = ({ tabs }) => {
  return (
    <Wrapper>
      {tabs.map((tab, i) => (
        <NavLink
          key={i}
          to={tab.to}
          className={({ isActive }) =>
            isActive ? `${styles.tab} ${styles.tabActive}` : styles.tab
          }
        >
          {tab.label}
        </NavLink>
      ))}
    </Wrapper>
  );
};

export default Tabstrip;
