import { FC } from "react";
import styled from "styled-components";
import FileUploader from "./components/FileUploader";
import UploadHistory from "./components/UploadHistory";
import { useFilesGetLatest } from "../../api/hooks/files/files";
import CenterContent from "components/CenterContent/CenterContent";

const Wrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

interface Props {}

const UploadPage: FC<Props> = () => {
  const filesQuery = useFilesGetLatest();

  return (
    <Wrapper>
      <CenterContent>
        <FileUploader onUpload={() => filesQuery.refetch()} />
        <UploadHistory
          history={filesQuery.data?.data ?? []}
          refetchHistory={
            filesQuery.isError ? () => filesQuery.refetch() : undefined
          }
          isLoading={filesQuery.isLoading}
        />
      </CenterContent>
    </Wrapper>
  );
};

export default UploadPage;
