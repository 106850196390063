import WarrantiesPage from "pages/WarrantiesPage";
import WarrantiesInvoiceTab from "pages/WarrantiesPage/InvoiceTab/InvoiceTab";
import WarrantiesSearchTab from "pages/WarrantiesPage/SearchTab";
import { Navigate, RouteObject } from "react-router-dom";
import LoginPage from "../../pages/LoginPage/LoginPage";
import UploadPage from "../../pages/UploadPage/UploadPage";
import { DeepReadonly } from "../../utils/object";
import generatePathsFromRouteObjects from "./generatePathsFromRouteObjects";

const readonlyRouteDefinitions = [
  {
    path: "",
    element: <Navigate to="upload" replace />,
  },
  {
    path: "upload",
    element: <UploadPage />,
  },

  {
    path: "login",
    element: <LoginPage />,
  },

  {
    path: "warranties",
    element: <WarrantiesPage />,
    children: [
      {
        path: "",
        element: <Navigate to="search" replace />,
      },
      {
        path: "search",
        element: <WarrantiesSearchTab />,
      },
      {
        path: "invoice",
        element: <WarrantiesInvoiceTab />,
      },
    ],
  },
] as const satisfies DeepReadonly<RouteObject[]>;

export const routeDefinitions =
  readonlyRouteDefinitions as any as RouteObject[];

const routes = generatePathsFromRouteObjects(readonlyRouteDefinitions, []);

export default routes;
