import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QueryKey, UseQueryResult } from "@tanstack/react-query";
import { ReactTable } from "ak-react-components";
import { warrantiesSearchInvoiceInformationFile } from "api/hooks/warranties/warranties";
import { ExternalInvoiceInformation } from "api/models";
import { AxiosError, AxiosResponse } from "axios";
import LinkButton from "components/LinkButton/LinkButton";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { FC, useState } from "react";
import { downloadBlob } from "utils/blob";
import { getDateString, getDateTimeString } from "utils/date";

interface Props {
  invoiceInformationFileNamesQuery: UseQueryResult<
    AxiosResponse<ExternalInvoiceInformation[], any>,
    AxiosError<unknown, any>
  > & {
    queryKey: QueryKey;
  };
}

const HistoryList: FC<Props> = ({ invoiceInformationFileNamesQuery }) => {
  const [downloadingFiles, setDownloadingFiles] = useState<Set<string>>(
    new Set(),
  );

  const handleDownloadClick = async (fileName: string) => {
    setDownloadingFiles((prev) => new Set(prev).add(fileName));

    try {
      const result = await warrantiesSearchInvoiceInformationFile({
        fileName,
      });

      if (result.data) downloadBlob(result.data, fileName);
    } finally {
      setDownloadingFiles((prev) => {
        const newSet = new Set(prev);
        newSet.delete(fileName);
        return newSet;
      });
    }
  };

  if (invoiceInformationFileNamesQuery.isLoading) return <LoadingSpinner />;

  return (
    <ReactTable
      asList
      columns={[
        {
          header: "Skapad",
          accessorFn: (row) => getDateTimeString(row.created),
          size: 150,
        },
        {
          header: "Startdatum",
          accessorFn: (row) => getDateString(row.fromDate),
          size: 120,
        },
        {
          header: "Slutdatum",
          accessorFn: (row) => getDateString(row.toDate),
          size: 120,
        },
        {
          header: "Kommentar",
          accessorKey: "comment",
          cell: (ctx) => <b>{ctx.cell.row.original.comment}</b>,
        },

        {
          header: "Ladda ner",
          enableSorting: false,
          size: 100,
          cell: (ctx) => (
            <LinkButton
              onClick={() => {
                handleDownloadClick(ctx.row.original.fileName);
              }}
              disabled={downloadingFiles.has(ctx.cell.row.original.fileName)}
              title={ctx.cell.row.original.fileName}
            >
              {downloadingFiles.has(ctx.cell.row.original.fileName) ? (
                <LoadingSpinner />
              ) : (
                <FontAwesomeIcon icon={faDownload} />
              )}
            </LinkButton>
          ),
        },
      ]}
      data={invoiceInformationFileNamesQuery.data?.data ?? []}
    />
  );
};

export default HistoryList;
