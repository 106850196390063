/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * AK Insurance Lookup API
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  AppTextsGetAppText200,
  ExternalAppTextInput,
  ExternalAppTextOutput,
} from "../../models";

export const appTextsGetAllAppTexts = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExternalAppTextOutput[]>> => {
  return axios.get(`/api/AppTexts`, options);
};

export const getAppTextsGetAllAppTextsQueryKey = () => {
  return [`/api/AppTexts`] as const;
};

export const getAppTextsGetAllAppTextsQueryOptions = <
  TData = Awaited<ReturnType<typeof appTextsGetAllAppTexts>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof appTextsGetAllAppTexts>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAppTextsGetAllAppTextsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof appTextsGetAllAppTexts>>
  > = ({ signal }) => appTextsGetAllAppTexts({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof appTextsGetAllAppTexts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AppTextsGetAllAppTextsQueryResult = NonNullable<
  Awaited<ReturnType<typeof appTextsGetAllAppTexts>>
>;
export type AppTextsGetAllAppTextsQueryError = AxiosError<unknown>;

export const useAppTextsGetAllAppTexts = <
  TData = Awaited<ReturnType<typeof appTextsGetAllAppTexts>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof appTextsGetAllAppTexts>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppTextsGetAllAppTextsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const appTextsGetAppText = (
  appTextKey: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<AppTextsGetAppText200>> => {
  return axios.get(`/api/AppTexts/${appTextKey}`, options);
};

export const getAppTextsGetAppTextQueryKey = (appTextKey: string) => {
  return [`/api/AppTexts/${appTextKey}`] as const;
};

export const getAppTextsGetAppTextQueryOptions = <
  TData = Awaited<ReturnType<typeof appTextsGetAppText>>,
  TError = AxiosError<unknown>,
>(
  appTextKey: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof appTextsGetAppText>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAppTextsGetAppTextQueryKey(appTextKey);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof appTextsGetAppText>>
  > = ({ signal }) =>
    appTextsGetAppText(appTextKey, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!appTextKey,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof appTextsGetAppText>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AppTextsGetAppTextQueryResult = NonNullable<
  Awaited<ReturnType<typeof appTextsGetAppText>>
>;
export type AppTextsGetAppTextQueryError = AxiosError<unknown>;

export const useAppTextsGetAppText = <
  TData = Awaited<ReturnType<typeof appTextsGetAppText>>,
  TError = AxiosError<unknown>,
>(
  appTextKey: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof appTextsGetAppText>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppTextsGetAppTextQueryOptions(appTextKey, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const appTextsUpsertAppText = (
  appTextKey: string,
  externalAppTextInput: ExternalAppTextInput,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExternalAppTextOutput>> => {
  return axios.put(
    `/api/AppTexts/${appTextKey}`,
    externalAppTextInput,
    options,
  );
};

export const getAppTextsUpsertAppTextMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appTextsUpsertAppText>>,
    TError,
    { appTextKey: string; data: ExternalAppTextInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof appTextsUpsertAppText>>,
  TError,
  { appTextKey: string; data: ExternalAppTextInput },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof appTextsUpsertAppText>>,
    { appTextKey: string; data: ExternalAppTextInput }
  > = (props) => {
    const { appTextKey, data } = props ?? {};

    return appTextsUpsertAppText(appTextKey, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AppTextsUpsertAppTextMutationResult = NonNullable<
  Awaited<ReturnType<typeof appTextsUpsertAppText>>
>;
export type AppTextsUpsertAppTextMutationBody = ExternalAppTextInput;
export type AppTextsUpsertAppTextMutationError = AxiosError<unknown>;

export const useAppTextsUpsertAppText = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appTextsUpsertAppText>>,
    TError,
    { appTextKey: string; data: ExternalAppTextInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getAppTextsUpsertAppTextMutationOptions(options);

  return useMutation(mutationOptions);
};
export const appTextsGetAppTextHistory = (
  appTextKey: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExternalAppTextOutput[]>> => {
  return axios.get(`/api/AppTexts/${appTextKey}/history`, options);
};

export const getAppTextsGetAppTextHistoryQueryKey = (appTextKey: string) => {
  return [`/api/AppTexts/${appTextKey}/history`] as const;
};

export const getAppTextsGetAppTextHistoryQueryOptions = <
  TData = Awaited<ReturnType<typeof appTextsGetAppTextHistory>>,
  TError = AxiosError<unknown>,
>(
  appTextKey: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof appTextsGetAppTextHistory>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAppTextsGetAppTextHistoryQueryKey(appTextKey);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof appTextsGetAppTextHistory>>
  > = ({ signal }) =>
    appTextsGetAppTextHistory(appTextKey, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!appTextKey,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof appTextsGetAppTextHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AppTextsGetAppTextHistoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof appTextsGetAppTextHistory>>
>;
export type AppTextsGetAppTextHistoryQueryError = AxiosError<unknown>;

export const useAppTextsGetAppTextHistory = <
  TData = Awaited<ReturnType<typeof appTextsGetAppTextHistory>>,
  TError = AxiosError<unknown>,
>(
  appTextKey: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof appTextsGetAppTextHistory>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppTextsGetAppTextHistoryQueryOptions(
    appTextKey,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
