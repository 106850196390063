export const Theme = {
  colors: {
    primary: "#E30613",
    primaryContrast: "#FFFFFF",
    disabled: "#CCCCCC",
    bg: {
      main: "#f7f7f7",
      content: "#FFFFFF",
      input: "#FFFFFF",
    },
    border: {
      main: "#CCCCCC",
      input: "#CCCCCC",
    },
    text: {
      main: "#000000",
      secondary: "#333333",
    },
    status: {
      success: "#00A300",
      error: "#E30613",
    },
  },
  sizes: {
    padding: { screenInset: 20 },
    font: {
      large: "24px",
      medium: "20px",
      small: "14px",
    },
  },
};
