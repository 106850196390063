/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * AK Insurance Lookup API
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  ExternalFile,
  FilesGetParams,
  FilesUploadFileBody,
} from "../../models";

export const filesGet = (
  params?: FilesGetParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Blob>> => {
  return axios.get(`/api/Files`, {
    responseType: "blob",
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getFilesGetQueryKey = (params?: FilesGetParams) => {
  return [`/api/Files`, ...(params ? [params] : [])] as const;
};

export const getFilesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof filesGet>>,
  TError = AxiosError<unknown>,
>(
  params?: FilesGetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof filesGet>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFilesGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof filesGet>>> = ({
    signal,
  }) => filesGet(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FilesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof filesGet>>
>;
export type FilesGetQueryError = AxiosError<unknown>;

export const useFilesGet = <
  TData = Awaited<ReturnType<typeof filesGet>>,
  TError = AxiosError<unknown>,
>(
  params?: FilesGetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof filesGet>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilesGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const filesUploadFile = (
  filesUploadFileBody: FilesUploadFileBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<string>> => {
  const formData = new FormData();
  if (
    filesUploadFileBody.file !== undefined &&
    filesUploadFileBody.file !== null
  ) {
    formData.append("file", filesUploadFileBody.file);
  }

  return axios.post(`/api/Files`, formData, options);
};

export const getFilesUploadFileMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof filesUploadFile>>,
    TError,
    { data: FilesUploadFileBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof filesUploadFile>>,
  TError,
  { data: FilesUploadFileBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof filesUploadFile>>,
    { data: FilesUploadFileBody }
  > = (props) => {
    const { data } = props ?? {};

    return filesUploadFile(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FilesUploadFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof filesUploadFile>>
>;
export type FilesUploadFileMutationBody = FilesUploadFileBody;
export type FilesUploadFileMutationError = AxiosError<unknown>;

export const useFilesUploadFile = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof filesUploadFile>>,
    TError,
    { data: FilesUploadFileBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getFilesUploadFileMutationOptions(options);

  return useMutation(mutationOptions);
};
export const filesGetLatest = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExternalFile[]>> => {
  return axios.get(`/api/Files/latest`, options);
};

export const getFilesGetLatestQueryKey = () => {
  return [`/api/Files/latest`] as const;
};

export const getFilesGetLatestQueryOptions = <
  TData = Awaited<ReturnType<typeof filesGetLatest>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof filesGetLatest>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFilesGetLatestQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof filesGetLatest>>> = ({
    signal,
  }) => filesGetLatest({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filesGetLatest>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FilesGetLatestQueryResult = NonNullable<
  Awaited<ReturnType<typeof filesGetLatest>>
>;
export type FilesGetLatestQueryError = AxiosError<unknown>;

export const useFilesGetLatest = <
  TData = Awaited<ReturnType<typeof filesGetLatest>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof filesGetLatest>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilesGetLatestQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
