import { useState } from "react";
import { toInputDateTimeString } from "utils/date";

export enum SearchType {
  Regnr = "Regnr",
  Chassinr = "Chassinr",
}

const useSearchControls = () => {
  const [searchType, setSearchType] = useState(SearchType.Regnr);
  const [searchInput, setSearchInput] = useState(""); // Reg or chassis number
  const [useDateRange, setUseDateRange] = useState(true);
  const [hideCancelled, setHideCancelled] = useState(true);

  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);

  const oneMonthAgo = new Date(startOfToday);
  oneMonthAgo.setMonth(startOfToday.getMonth() - 1);

  const endOfToday = new Date(startOfToday);
  endOfToday.setHours(23, 59, 59, 999);

  const [fromDateStr, setFromDateStr] = useState<string>(
    toInputDateTimeString(oneMonthAgo),
  );
  const [toDateStr, setToDateStr] = useState<string>(
    toInputDateTimeString(endOfToday),
  );

  const fromDate = new Date(fromDateStr);
  const toDate = new Date(toDateStr);
  const areDatesValid = !isNaN(fromDate.getTime()) && !isNaN(toDate.getTime());

  const canSearch = useDateRange ? areDatesValid : !!searchInput;

  return {
    searchType,
    setSearchType,
    searchInput,
    setSearchInput,
    useDateRange,
    setUseDateRange,

    fromDateStr,
    setFromDateStr,
    toDateStr,
    setToDateStr,
    areDatesValid,
    canSearch,
    hideCancelled,
    setHideCancelled,

    fromDate,
    toDate,
  };
};

export default useSearchControls;
