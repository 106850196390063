import axios from 'axios';
import { useLayoutEffect } from 'react';

const isoDateFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object')
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      body[key] = new Date(value);
    } else if (typeof value === 'object') {
      handleDates(value);
    }
  }
}

/**
 * This hook configures Axios to convert dates in responses from string to a javascript Date object.
 * The Orval configuration only generates types for date fields, and doesn't convert dates on its own.
 */
function useAxiosDateConverter() {
  useLayoutEffect(() => {
    const interceptor = axios.interceptors.response.use((originalResponse) => {
      handleDates(originalResponse.data);

      return originalResponse;
    });

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  });
}

export default useAxiosDateConverter;
