import { addClass } from "ak-react-components";
import "ak-react-components/dist/index.css";
import { FC, useCallback, useState } from "react";
import { toInputDateTimeString } from "utils/date";
import styles from "./index.module.scss";
import WarrantiesTable from "./WarrantiesTable";
import { useWarrantiesSearchWarranties } from "api/hooks/warranties/warranties";
import { ExternalWarrantyModel } from "api/models";
import EditWarrantySlideIn from "./EditWarrantySlideIn/EditWarrantySlideIn";
import { useForm } from "react-hook-form";
import {
  convertWarrantyToEditWarrantyForm,
  EditWarrantyForm,
} from "./EditWarrantySlideIn/utils/formConverters";
import SearchControls from "./SearchControls/SearchControls";
import useSearchControls, {
  SearchType,
} from "./SearchControls/useSearchControls";

const OuterWrapper = addClass("div", styles.outerWrapper);
const SearchWrapper = addClass("div", styles.searchWrapper);

const WarrantiesSearchTab: FC = () => {
  const searchControls = useSearchControls();
  const [selectedWarranty, setSelectedWarranty] =
    useState<ExternalWarrantyModel | null>(null);
  const [editSelectedWarranty, setEditSelectedWarranty] = useState(false);

  const warrantyForm = useForm<EditWarrantyForm>({
    defaultValues: convertWarrantyToEditWarrantyForm(),
  });

  const searchMutation = useWarrantiesSearchWarranties();

  const onSearchClick = () => {
    const {
      searchInput,
      searchType,
      areDatesValid,
      useDateRange,
      fromDate,
      toDate,
    } = searchControls;

    searchMutation.mutate({
      data: {
        chassisNumber:
          searchType === SearchType.Chassinr ? searchInput : undefined,
        registrationNumber:
          searchType === SearchType.Regnr ? searchInput : undefined,

        period:
          areDatesValid && useDateRange
            ? {
                fromDate,
                toDate,
              }
            : undefined,
      },
    });
  };

  const handleRowClick = useCallback(
    (warranty: ExternalWarrantyModel) => {
      setSelectedWarranty(warranty);
      setEditSelectedWarranty(false);
      warrantyForm.reset(convertWarrantyToEditWarrantyForm(warranty));
    },
    [warrantyForm],
  );

  return (
    <OuterWrapper>
      <SearchWrapper>
        <SearchControls
          searchControls={searchControls}
          onSearchClick={onSearchClick}
          disableSearchButton={
            !searchControls.canSearch || searchMutation.isPending
          }
          isSearching={searchMutation.isPending}
        />

        <WarrantiesTable
          warranties={searchMutation.data?.data.warranties ?? []}
          hideCancelled={searchControls.hideCancelled}
          onRowClick={handleRowClick}
          selectedWarrantyId={selectedWarranty?.id}
        />
      </SearchWrapper>

      {selectedWarranty && (
        <EditWarrantySlideIn
          form={warrantyForm}
          onCloseClick={() => {
            setSelectedWarranty(null);
            warrantyForm.reset(convertWarrantyToEditWarrantyForm());
            setEditSelectedWarranty(false);
          }}
          onWarrantySaved={() => {
            onSearchClick();
            setEditSelectedWarranty(false);
          }}
          editMode={editSelectedWarranty}
          onCancelEditClick={() => {
            setEditSelectedWarranty(false);
            warrantyForm.reset();
          }}
          onEditClick={() => setEditSelectedWarranty(true)}
        />
      )}
    </OuterWrapper>
  );
};

export default WarrantiesSearchTab;
