import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Wrapper = styled.span`
  opacity: 0.7;
  margin: 0 auto;
`;

const LoadingSpinner = () => {
  return (
    <Wrapper>
      <FontAwesomeIcon icon={faSpinner} spin />
    </Wrapper>
  );
};

export default LoadingSpinner;
