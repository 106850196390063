import { addClass, Input, InputGroup, Select } from "ak-react-components";
import { InputConfig } from "ak-react-components/dist/types/components/inputs/InputGroup/InputGroup";
import { ReactNode, useId } from "react";
import {
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormReturn,
} from "react-hook-form";
import { toInputDateString, toInputDateTimeString } from "utils/date";
import styles from "./FormSection.module.scss";

export const FormSectionInput = addClass(Input, [], {
  $readonlyStyle: styles.readonlyStyle,
});

export enum InputType {
  Custom = "custom",
  Text = "text",
  Number = "number",
  Date = "date",
  DateTime = "datetime-local",
  Select = "select",
}

type InputDefinition<T extends FieldValues> =
  | {
      type:
        | InputType.Text
        | InputType.Number
        | InputType.Date
        | InputType.DateTime;
      name: Path<T>;
      label?: ReactNode;
      registerOptions?: RegisterOptions<T, Path<T>>;
    }
  | {
      type: InputType.Select;
      name: Path<T>;
      label?: ReactNode;
      options: { value: string; label: string }[];
      registerOptions?: RegisterOptions<T, Path<T>>;
    }
  | {
      type: InputType.Custom;
      label?: ReactNode;
      labelFor?: string;
      render: (props: {
        form: UseFormReturn<T>;
        readonly: boolean;
      }) => ReactNode;
    };

interface Props<T extends FieldValues> {
  form: UseFormReturn<T>;
  inputs: InputDefinition<T>[];
  readonly?: boolean;
}

const FormSection = <T extends FieldValues>({
  form,
  inputs,
  readonly,
}: Props<T>) => {
  const uid = useId();

  return (
    <InputGroup
      inputs={inputs.map((input): InputConfig => {
        const inputId = `${uid}-${"name" in input ? input.name : ""}`;

        switch (input.type) {
          case InputType.Text:
          case InputType.Number: {
            const { pattern, valueAsNumber, valueAsDate, ...restOptions } =
              input.registerOptions ?? {};

            const options: RegisterOptions<T, Path<T>> = {
              ...restOptions,

              ...(valueAsDate
                ? { valueAsDate: true }
                : input.type === InputType.Number || valueAsNumber
                  ? { valueAsNumber: true }
                  : { pattern }),
            };

            return {
              label: input.label,
              labelFor: inputId,
              input: (
                <FormSectionInput
                  $readonlyStyle={readonly}
                  readOnly={readonly}
                  type={input.type}
                  id={inputId}
                  {...form.register(input.name, options)}
                />
              ),
            };
          }

          case InputType.Date:
          case InputType.DateTime:
            return {
              label: input.label,
              labelFor: inputId,
              input: (
                <Controller
                  control={form.control}
                  name={input.name}
                  render={({ field }) => (
                    <FormSectionInput
                      $readonlyStyle={readonly}
                      readOnly={readonly}
                      type={input.type}
                      id={inputId}
                      {...field}
                      value={
                        input.type === InputType.Date
                          ? toInputDateString(field.value)
                          : toInputDateTimeString(field.value)
                      }
                      onChange={(eve) => {
                        const parsedDate = new Date(
                          input.type === InputType.Date
                            ? `${eve.target.value}T00:00:00`
                            : eve.target.value,
                        );

                        field.onChange(
                          isNaN(parsedDate.getTime()) ? null : parsedDate,
                        );
                      }}
                    />
                  )}
                />
              ),
            };

          case InputType.Select:
            return {
              label: input.label,
              labelFor: inputId,
              input: (
                <Select
                  id={inputId}
                  {...form.register(input.name, input.registerOptions)}
                >
                  {input.options.map((option, i) => (
                    <option value={option.value} key={i}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              ),
            };

          case InputType.Custom:
            return {
              label: input.label,
              labelFor: input.labelFor,
              input: input.render({ form, readonly: !!readonly }),
            };
        }
      })}
    />
  );
};

export default FormSection;
