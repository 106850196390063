import { FC, useState } from "react";
import { addClass } from "ak-react-components";
import styles from "./InfoBox.module.scss";
import RichTextEditor from "components/RichTextEditor/RichTextEditor";
import LoadingSpinnerOverlay from "components/LoadingSpinner/LoadingSpinnerOverlay";
import {
  getAppTextsGetAppTextQueryKey,
  useAppTextsGetAppText,
  useAppTextsUpsertAppText,
} from "api/hooks/app-texts/app-texts";
import { useQueryClient } from "@tanstack/react-query";

const Wrapper = addClass("div", styles.wrapper);
const MyRichTextEditor = addClass(RichTextEditor, styles.richTextEditor);

interface Props {
  appTextKey: string;
}

const InfoBox: FC<Props> = ({ appTextKey }) => {
  const [editMode, setEditMode] = useState(false);

  const queryClient = useQueryClient();
  const infoTextQuery = useAppTextsGetAppText(appTextKey);
  const infoTextMutation = useAppTextsUpsertAppText({
    mutation: {
      onSuccess(data) {
        queryClient.setQueryData(
          getAppTextsGetAppTextQueryKey(appTextKey),
          data,
        );
        setEditMode(false);
      },
    },
  });

  const handleSaveClick = async (htmlText: string) => {
    infoTextMutation.mutate({
      appTextKey,
      data: {
        text: htmlText,
      },
    });
  };

  return (
    <Wrapper>
      {infoTextQuery.isLoading ? (
        <LoadingSpinnerOverlay>Laddar...</LoadingSpinnerOverlay>
      ) : (
        <MyRichTextEditor
          htmlText={infoTextQuery.data?.data?.text ?? ""}
          onSaveClick={editMode ? handleSaveClick : undefined}
          onCancelClick={() => setEditMode(false)}
          onEditClick={() => setEditMode(!editMode)}
        />
      )}

      {infoTextMutation.isPending && (
        <LoadingSpinnerOverlay>Sparar...</LoadingSpinnerOverlay>
      )}
    </Wrapper>
  );
};

export default InfoBox;
