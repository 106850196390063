import { addClass } from "ak-react-components";
import { FC, PropsWithChildren, ReactNode } from "react";
import styles from "./index.module.scss";
import Tabstrip, { Tab } from "./Tabstrip";

const Wrapper = addClass("div", styles.wrapper);
const Head = addClass("div", styles.head);

interface Props extends PropsWithChildren {
  title?: ReactNode;
  tabs?: Tab[];
}

const Page: FC<Props> = ({ title, tabs, children }) => {
  return (
    <Wrapper>
      <Head>
        <h1>{title}</h1>

        {tabs?.length && <Tabstrip tabs={tabs} />}
      </Head>

      {children}
    </Wrapper>
  );
};

export default Page;
