/** This file is generated by the updateStyleRules.js script. Do not modify it manually. */

export const cssVar = {
  "theme-colors-bg-accent1": "--theme-colors-bg-accent1",
  "theme-colors-bg-1": "--theme-colors-bg-1",
  "theme-colors-bg-2": "--theme-colors-bg-2",
  "theme-colors-bg-3": "--theme-colors-bg-3",
  "theme-colors-bg-selected": "--theme-colors-bg-selected",
  "theme-colors-bg-overlay": "--theme-colors-bg-overlay",
  "theme-colors-fg-accent1": "--theme-colors-fg-accent1",
  "theme-colors-fg-1": "--theme-colors-fg-1",
  "theme-colors-fg-2": "--theme-colors-fg-2",
  "theme-colors-fg-3": "--theme-colors-fg-3",
  "theme-colors-fg-selected": "--theme-colors-fg-selected",
  "theme-colors-border-main": "--theme-colors-border-main",
  "theme-colors-border-input": "--theme-colors-border-input",
  "theme-colors-status-success": "--theme-colors-status-success",
  "theme-colors-status-warning": "--theme-colors-status-warning",
  "theme-colors-status-error": "--theme-colors-status-error",
  "theme-sizes-padding-screeninset": "--theme-sizes-padding-screeninset",
  "theme-sizes-font-large": "--theme-sizes-font-large",
  "theme-sizes-font-medium": "--theme-sizes-font-medium",
  "theme-sizes-font-small": "--theme-sizes-font-small"
} as const;

export const cssVarRef = {
  "theme-colors-bg-accent1": "var(--theme-colors-bg-accent1)",
  "theme-colors-bg-1": "var(--theme-colors-bg-1)",
  "theme-colors-bg-2": "var(--theme-colors-bg-2)",
  "theme-colors-bg-3": "var(--theme-colors-bg-3)",
  "theme-colors-bg-selected": "var(--theme-colors-bg-selected)",
  "theme-colors-bg-overlay": "var(--theme-colors-bg-overlay)",
  "theme-colors-fg-accent1": "var(--theme-colors-fg-accent1)",
  "theme-colors-fg-1": "var(--theme-colors-fg-1)",
  "theme-colors-fg-2": "var(--theme-colors-fg-2)",
  "theme-colors-fg-3": "var(--theme-colors-fg-3)",
  "theme-colors-fg-selected": "var(--theme-colors-fg-selected)",
  "theme-colors-border-main": "var(--theme-colors-border-main)",
  "theme-colors-border-input": "var(--theme-colors-border-input)",
  "theme-colors-status-success": "var(--theme-colors-status-success)",
  "theme-colors-status-warning": "var(--theme-colors-status-warning)",
  "theme-colors-status-error": "var(--theme-colors-status-error)",
  "theme-sizes-padding-screeninset": "var(--theme-sizes-padding-screeninset)",
  "theme-sizes-font-large": "var(--theme-sizes-font-large)",
  "theme-sizes-font-medium": "var(--theme-sizes-font-medium)",
  "theme-sizes-font-small": "var(--theme-sizes-font-small)"
} as const;