/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * AK Insurance Lookup API
 * OpenAPI spec version: v1
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type { Credentials } from "../../models";

export const userLogin = (
  credentials: Credentials,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<string>> => {
  return axios.post(`/api/User/login`, credentials, options);
};

export const getUserLoginMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userLogin>>,
    TError,
    { data: Credentials },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userLogin>>,
  TError,
  { data: Credentials },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userLogin>>,
    { data: Credentials }
  > = (props) => {
    const { data } = props ?? {};

    return userLogin(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof userLogin>>
>;
export type UserLoginMutationBody = Credentials;
export type UserLoginMutationError = AxiosError<unknown>;

export const useUserLogin = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userLogin>>,
    TError,
    { data: Credentials },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUserLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
export const userLogOut = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Blob>> => {
  return axios.post(`/api/User/logout`, undefined, {
    responseType: "blob",
    ...options,
  });
};

export const getUserLogOutMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userLogOut>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userLogOut>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userLogOut>>,
    void
  > = () => {
    return userLogOut(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserLogOutMutationResult = NonNullable<
  Awaited<ReturnType<typeof userLogOut>>
>;

export type UserLogOutMutationError = AxiosError<unknown>;

export const useUserLogOut = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userLogOut>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUserLogOutMutationOptions(options);

  return useMutation(mutationOptions);
};
