import { ExternalUpdateWarrantyInput, ExternalWarrantyModel } from "api/models";

export interface EditWarrantyForm {
  id: number;
  registrationNumber: string;
  chassisNumber: string;
  vehicleModel: string;
  vehicleBrand: string;
  vehicleCountry: string;
  vehicleYearModel: string;
  validFrom?: Date;
  validTo?: Date;
  externalId: string;
  cancelled: boolean;
  totalWarrantyYears: number | null;
}

export function convertWarrantyToEditWarrantyForm(
  warranty?: ExternalWarrantyModel,
): EditWarrantyForm {
  return {
    id: warranty?.id ?? 0,
    registrationNumber: warranty?.registrationNumber ?? "",
    chassisNumber: warranty?.chassisNumber ?? "",
    vehicleModel: warranty?.vehicleModel ?? "",
    vehicleBrand: warranty?.vehicleBrand ?? "",
    vehicleCountry: warranty?.vehicleCountry ?? "",
    vehicleYearModel: warranty?.vehicleYearModel ?? "",
    validFrom: warranty?.validFrom,
    validTo: warranty?.validTo,
    externalId: warranty?.externalId ?? "",
    cancelled: warranty?.cancelled ?? false,
    totalWarrantyYears:
      warranty?.totalWarrantyYears !== null &&
      warranty?.totalWarrantyYears !== undefined
        ? Number(warranty.totalWarrantyYears)
        : null,
  };
}

export function convertEditWarrantyFormToWarranty(
  form: EditWarrantyForm,
): ExternalUpdateWarrantyInput {
  if (!form.validFrom || !form.validTo) {
    // This should be handled by the form validation already
    throw new Error("Valid from and valid to are required");
  }

  return {
    id: form.id,
    registrationNumber: form.registrationNumber,
    chassisNumber: form.chassisNumber,
    vehicleModel: form.vehicleModel,
    vehicleBrand: form.vehicleBrand,
    vehicleCountry: form.vehicleCountry,
    vehicleYearModel: form.vehicleYearModel,
    validFrom: form.validFrom,
    validTo: form.validTo,
    externalId: form.externalId,
    cancelled: form.cancelled,
    totalWarrantyYears:
      form.totalWarrantyYears === null ? null : String(form.totalWarrantyYears),
  };
}
