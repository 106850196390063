import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FC } from "react";
import styles from "./Header.module.scss";
import PageLink from "./PageLink";
import routes from "constants/routes";
import { addClass } from "ak-react-components";

const Wrapper = addClass("div", styles.wrapper);
const Logo = addClass("div", styles.logo);
const PageLinks = addClass("ul", styles.pageLinks);
const LogoutLink = addClass(NavLink, styles.logOutLink);

interface Props {
  isLoggedIn: boolean;
  onLogOut: () => void;
}

const Header: FC<Props> = ({ isLoggedIn, onLogOut }) => {
  return (
    <Wrapper>
      <Logo />

      {isLoggedIn && (
        <PageLinks>
          <PageLink to={routes.upload}>Ladda upp försäkringar</PageLink>
          <PageLink to={routes.warranties.index}>Garantier</PageLink>
        </PageLinks>
      )}

      {isLoggedIn && (
        <span>
          <FontAwesomeIcon icon={faLock} />
          <LogoutLink onClick={onLogOut} to="/login">
            Logga ut
          </LogoutLink>
        </span>
      )}
    </Wrapper>
  );
};

export default Header;
