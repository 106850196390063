import { faTrashAlt, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addClass,
  classNames as tableClassNames,
  ReactTable,
  StrictColumnDef,
} from "ak-react-components";
import { ExternalWarrantyModel } from "api/models";
import classNames from "classnames";
import { cssVarRef } from "constants/cssVars";
import { FC, memo, useMemo } from "react";
import { getDateString } from "utils/date";
import styles from "./WarrantiesTable.module.scss";

const Table = addClass(
  ReactTable,
  styles.warrantiesTable,
) as any as typeof ReactTable;

const columnDefinitions: StrictColumnDef<
  ExternalWarrantyModel & { warnDifferingWarrantyYears: boolean }
>[] = [
  {
    header: "",
    accessorKey: "cancelled",
    size: 50,
    minSize: 50,
    cell: ({ row }) =>
      row.original.cancelled && (
        <FontAwesomeIcon icon={faTrashAlt} title="Makulerad" />
      ),
  },
  {
    header: "Regnr",
    accessorKey: "registrationNumber",
    size: 130,
  },
  {
    header: "Chassinr",
    accessorKey: "chassisNumber",
    size: 190,
  },
  {
    header: "Modell",
    accessorKey: "vehicleModel",
    size: 320,
  },
  {
    header: "Märke",
    accessorKey: "vehicleBrand",
  },
  {
    header: "Land",
    accessorKey: "vehicleCountry",
  },
  {
    header: "Årsmodell",
    accessorKey: "vehicleYearModel",
  },
  {
    header: "Giltig från",
    accessorKey: "validFrom",
    accessorFn: ({ validFrom }) => getDateString(validFrom),
  },
  {
    header: "Giltig till",
    accessorFn: ({ validTo }) => getDateString(validTo),
  },
  {
    header: "Antal garantiår",
    accessorKey: "totalWarrantyYears",
    cell: ({ row }) => {
      return (
        <>
          {row.original.totalWarrantyYears ?? "N/A"}{" "}
          {row.original.warnDifferingWarrantyYears && (
            <FontAwesomeIcon
              icon={faWarning}
              title="OBS: stämmer inte överens med giltighetsdatumen"
            />
          )}
        </>
      );
    },
  },
  {
    header: "Externt id",
    accessorKey: "externalId",
  },
  {
    header: "Extern partner",
    accessorKey: "externalPartner",
  },
  {
    header: "Skapad",
    accessorKey: "created",
    accessorFn: ({ created }) => getDateString(created),
  },
  {
    header: "Uppdaterad",
    accessorFn: ({ updated }) => getDateString(updated),
  },
];

interface Props {
  warranties: ExternalWarrantyModel[];
  hideCancelled: boolean;
  onRowClick?: (row: ExternalWarrantyModel) => void;
  selectedWarrantyId?: number;
}

const WarrantiesTable: FC<Props> = memo(
  ({ warranties, hideCancelled, onRowClick, selectedWarrantyId }) => {
    const warrantiesViewmodels = useMemo(() => {
      return warranties
        .filter((w) => !hideCancelled || !w.cancelled)
        .map((warranty) => {
          const validYearSpan =
            warranty.validTo.getFullYear() - warranty.validFrom.getFullYear();
          const totalWarrantyYears = Number(warranty.totalWarrantyYears);

          return {
            ...warranty,

            warnDifferingWarrantyYears: !!(
              warranty.totalWarrantyYears &&
              !isNaN(totalWarrantyYears) &&
              validYearSpan !== totalWarrantyYears
            ),
          };
        });
    }, [warranties, hideCancelled]);

    return (
      <Table
        onRowClick={onRowClick ? (row) => onRowClick(row) : undefined}
        insetPadding={cssVarRef["theme-sizes-padding-screeninset"]}
        pagination
        initialSortingState={[
          {
            id: "created",
            desc: true,
          },
          {
            id: "validFrom",
            desc: true,
          },
        ]}
        getRowId={(row) => `${row.id}`}
        getRowClassName={(row) =>
          classNames({
            [tableClassNames.rowIsSelected]:
              selectedWarrantyId === row.original.id,
            [styles.canceledWarrantyRow]: row.original.cancelled,
          })
        }
        columns={columnDefinitions}
        data={warrantiesViewmodels}
      />
    );
  },
);

export default WarrantiesTable;
