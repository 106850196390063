import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addClass, Button } from "ak-react-components";
import { FC, PropsWithChildren, ReactNode } from "react";
import styles from "./SlideIn.module.scss";

const ExpandWrapper = addClass("div", styles.expandWrapper);
const Wrapper = addClass("div", styles.wrapper);
const TitleRow = addClass("div", styles.titleRow);
const MainContent = addClass("div", styles.mainContent);
const ActionsRow = addClass("div", styles.actionsRow);
const CloseButton = addClass("button", styles.closeButton);

export interface Action {
  label: string;
  onClick: () => void;
  icon?: ReactNode;
  disabled?: boolean;
  hide?: boolean;
}

interface Props extends PropsWithChildren {
  title: ReactNode;
  actions?: Action[];
  onClose?: () => void;
}

const SlideIn: FC<Props> = ({ title, actions, onClose, children }) => {
  const filteredActions = actions?.filter((a) => !a.hide);

  return (
    <ExpandWrapper>
      <Wrapper>
        <TitleRow>
          <h2>{title}</h2>
          {onClose && (
            <CloseButton onClick={onClose}>
              <FontAwesomeIcon icon={faClose} />
            </CloseButton>
          )}
        </TitleRow>
        <MainContent>{children}</MainContent>
        {filteredActions?.length && (
          <ActionsRow>
            {filteredActions.map((a, i) => (
              <Button key={i} onClick={a.onClick} disabled={a.disabled}>
                {a.icon} {a.label}
              </Button>
            ))}
          </ActionsRow>
        )}
      </Wrapper>
    </ExpandWrapper>
  );
};

export default SlideIn;
