import { addClass } from "ak-react-components";
import { FC, PropsWithChildren } from "react";
import styles from "./index.module.scss";

const Wrapper = addClass("div", styles.wrapper);
const InnerWrapper = addClass("div", styles.innerWrapper);

export const PageControlsRow = addClass("div", styles.pageControlsRow);

export const PageControls: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Wrapper>
      <InnerWrapper>{children}</InnerWrapper>
    </Wrapper>
  );
};
